import Vue from 'vue'
import {
  Theme,
  Input,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  RadioButton,
  Col,
  Row,
  Drawer,
  Select,
  Option,
  Stepper,
  Collapse,
  CollapseItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  MessageBox,
  Loading,
  Dialog,
  Message,
  Popover,
  NewAlert,
  Floating,
  Tooltip,
  Alert,
  Toast,
  Switch,
  PageIndicator,
  PageIndicatorItem,
} from '@didi/r-fusion-pc'
// collapse 展开折叠 动画
import CollapseTransition from '@didi/r-fusion-pc/lib/transitions/collapse-transition'

import locale from '@didi/r-fusion-pc/lib/locale'
import '../common/style/r-fusion-pc-variables.scss'
import { rFusionPCLangList } from './langList'
import { getLanguage, getRegion } from '@/utils/locale'

Vue.component(CollapseTransition.name, CollapseTransition)

Vue.use(Input)
  .use(Button)
  .use(Dialog)
  .use(Radio)
  .use(RadioGroup)
  .use(RadioButton)
  .use(Checkbox)
  .use(Col)
  .use(Row)
  .use(Drawer)
  .use(Select)
  .use(Option)
  .use(Stepper)
  .use(Collapse)
  .use(CollapseItem)
  .use(Dropdown)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Popover)
  .use(Theme)
  .use(Loading)
  .use(Floating)
  .use(Tooltip)
  .use(Alert)
  .use(Switch)
  .use(PageIndicator)
  .use(PageIndicatorItem)

// Vue.prototype.$ELEMENT = { size: 'small' };
Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$newalert = NewAlert
Vue.prototype.$ralert = NewAlert.alert
Vue.prototype.$rconfirm = NewAlert.confirm
Vue.prototype.$rprompt = NewAlert.prompt
Vue.prototype.$toast = Toast
locale.use(rFusionPCLangList[getLanguage().short])

const THEME = (() => {
  return getRegion() === 'BR' ? '99' : 'didi'
})()

if (THEME === '99') {
  Theme.use('99')
} else {
  Theme.use('didi')
}
