import langEsMX from '@didi/r-fusion-pc/lib/locale/lang/es'
import langEnUS from '@didi/r-fusion-pc/lib/locale/lang/en'
import langZhCN from '@didi/r-fusion-pc/lib/locale/lang/zh-CN'
import langPtBR from '@didi/r-fusion-pc/lib/locale/lang/pt-br'

export const rFusionPCLangList = {
  en: langEnUS,
  es: langEsMX,
  zh: langZhCN,
  pt: langPtBR,
}
